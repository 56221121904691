import React, { useMemo } from 'react'
import Image from 'next/image'
import { Navbar } from '@sailogy/shipwright'

interface Props {
  overlay?: boolean
}

const Logo = () => (
  <Image src="https://sailogy-pictures-public.imgix.net/img/bviyachtscharter-logo.png" alt="BVI Yacht Charter" height={34} width={165} />
)


const WebsiteNavbar: React.FC<Props> = ({ overlay }) => {
  const menu = useMemo(() => [
    // {
    //   label: 'About us',
    //   menu: [
    //     {
    //       label: 'Online brochure',
    //       href: 'https://www.bviyachtcharters.com/online-brochure/'
    //     },
    //     {
    //       label: 'Why BVI Yacht Charters?',
    //       href: 'https://www.bviyachtcharters.com/about-us/'
    //     },
    //     {
    //       label: 'Meet our team',
    //       href: 'https://www.bviyachtcharters.com/british-virgin-islands/meet-our-team/'
    //     },
    //     {
    //       label: 'Client testimonials',
    //       href: 'https://www.bviyachtcharters.com/testimonials/'
    //     },
    //     {
    //       label: 'Blog',
    //       href: 'https://www.bviyachtcharters.com/british-virgin-islands/chartering-blog/'
    //     },
    //     {
    //       label: 'Newsletters',
    //       href: 'https://www.bviyachtcharters.com/bvi-newsletters/'
    //     },
    //     {
    //       label: 'Privacy policy',
    //       href: 'https://www.bviyachtcharters.com/privacy-policy/'
    //     }
    //   ]
    // },
    // {
    //   label: 'BVI',
    //   menu: [
    //     {
    //       label: 'British Virgin Islands',
    //       href: 'https://www.bviyachtcharters.com/british-virgin-islands/'
    //     },
    //     {
    //       label: 'Sample itinerary',
    //       href: 'https://www.bviyachtcharters.com/british-virgin-islands/bvi-sample-itinerary/'
    //     },
    //     {
    //       label: 'Charter extras',
    //       href: 'https://www.bviyachtcharters.com/british-virgin-islands/bvi-accessories/'
    //     },
    //     {
    //       label: 'Fishing onboard',
    //       href: 'https://www.bviyachtcharters.com/british-virgin-islands/fishing-on-board/'
    //     },
    //     {
    //       label: 'Provisioning',
    //       href: 'https://www.bviyachtcharters.com/british-virgin-islands/provisioning-bvi/'
    //     },
    //     {
    //       label: 'Restaurants in the BVI',
    //       href: 'https://www.bviyachtcharters.com/restaurants-in-the-bvi/'
    //     },
    //     {
    //       label: 'Weather',
    //       href: 'https://www.bviyachtcharters.com/bvi-weather/'
    //     },
    //     {
    //       label: 'Common questions',
    //       href: 'https://www.bviyachtcharters.com/general-questions-about-chartering/'
    //     }
    //   ]
    // },
    // {
    //   label: 'Bareboat fleet',
    //   menu: [
    //     {
    //       label: 'Catamaran yachts',
    //       href: 'https://www.bviyachtcharters.com/british-virgin-islands/bvi-catamarans/'
    //     },
    //     {
    //       label: 'Monohull yachts',
    //       href: 'https://www.bviyachtcharters.com/british-virgin-islands/bvi-monohulls/'
    //     }
    //   ]
    // },
    // {
    //   label: 'All-inclusive yachts',
    //   menu: [
    //     {
    //       label: 'All-inclusive charters',
    //       href: 'https://www.bviyachtcharters.com/all-inclusive-crewed-charters/'
    //     },
    //     {
    //       label: 'Crewed testimonials',
    //       href: 'https://www.bviyachtcharters.com/all-inclusive-crewed-charters/crewed-charter-testimonials/'
    //     }
    //   ]
    // },
    // {
    //   label: 'Ownership',
    //   href: 'https://www.bviyachtcharters.com/yacht-sales/'
    // },
    // {
    //   label: 'Destinations',
    //   menu: [
    //     {
    //       label: 'Corfù - Jewel of the Greek Ionian Islands',
    //       href: 'https://www.bviyachtcharters.com/corfu/'
    //     },
    //     {
    //       label: 'Ibiza & Formentera Islands, Spain',
    //       href: 'https://www.bviyachtcharters.com/spain/'
    //     },
    //     {
    //       label: 'Kremik, Croatia fleet',
    //       href: 'https://www.bviyachtcharters.com/croatia/croatia-yacht-charters-bareboat-fleet/'
    //     },
    //     {
    //       label: 'Oca Maloca vacation apartment',
    //       href: 'https://www.bviyachtcharters.com/oca-maloca-vacation-apartment/'
    //     },
    //     {
    //       label: 'Porto Colom, Mallorca',
    //       href: 'https://www.bviyachtcharters.com/mallorca/mallorca-yacht-charters-bareboat-fleet/'
    //     },
    //     {
    //       label: 'Thailand',
    //       href: 'https://www.bviyachtcharters.com/thailand/'
    //     },
    //     {
    //       label: 'US Virgin Islands',
    //       href: 'https://www.bviyachtcharters.com/us-virgin-islands/'
    //     },
    //     {
    //       label: 'Saint Vincent and the Grenadines',
    //       href: 'https://www.bviyachtcharters.com/saint-vincent-and-the-grenadines/'
    //     },
    //     {
    //       label: 'Whitsundays, Australia',
    //       href: 'https://www.bviyachtcharters.com/australia-charters/'
    //     }
    //   ]
    // },
    // {
    //   label: 'Specials',
    //   href: 'https://www.bviyachtcharters.com/special-offers/'
    // }
  ], [])

  return <Navbar logo={Logo} menu={menu} overlay={overlay} />
}

export default React.memo(WebsiteNavbar)
