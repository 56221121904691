import Head from 'components/Head'
import Navbar from 'components/Navbar'
import { withI18nStaticProps } from 'utils/request/ssg'
import {
  NavigationProvider,
  UserDashboard,
  useTranslation
} from '@sailogy/shipwright'
import { i18nPath, K } from 'i18n/user_profile'


const UserProfile: React.FC = () => {
  const t = useTranslation<K>()

  return (
    <NavigationProvider path="/user_dashboard">
      <Head
        title={t('Profile')}
        noIndex
      />
      <Navbar />
      <UserDashboard />
    </NavigationProvider>
  )
}

// Build SSG pages for each language
export const getStaticProps = withI18nStaticProps(i18nPath)

export default UserProfile
